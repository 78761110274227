<template>
  <div class="newList bgI">
    <div class="topbananr">
      <img src="../../assets/images/cont_bg.jpg" alt="" style="width:100%">
    </div>
    <div class="content">
      <h4>关于我们</h4>
      <p class="info">老邦讲堂是劳务邦旗下专注于公开课服务的子品牌，发展11年多来整合劳务行业各领域核心资源，成功打造知行并重、多快好省的一站式培训平台，老板省心、员工称心的全品类培训体系。课程涵盖新进员工、企业文化，法律法规、技能培训、重大事件、异常事件等9大系列</p>
    </div>
    <div class="twoC">
      <div class="content">
        <h4>公司文化</h4>
        <div class="three">
          <div v-for="li in threeList">
            <img :src="li.img" alt="">
            <p class="title">{{ li.title }}</p>
            <span v-html="li.info" class="info"></span>
          </div>
        </div>
<!--        <h4>资质</h4>-->
<!--        <div class="sixbox">-->
<!--          <div class="list">-->
<!--            <div class="box"></div>-->
<!--            <div class="box"></div>-->
<!--            <div class="box"></div>-->
<!--            <div class="box"></div>-->
<!--            <div class="box"></div>-->
<!--            <div class="box"></div>-->
<!--          </div>-->
<!--        </div>-->
        <h4>联系我们</h4>
      </div>
      <div class="listInfo">
           <div class="listbox">
              <ul>
                <li v-for="list in listList">
                  <span>{{ list.title }}</span> {{ list.info }}
                </li>
              </ul>
           </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "contactPage",
  data() {
    return {
      threeList: [
        {title: "愿景", img: require("@/assets/images/yj.png"), info:" 做世界领先的<br/>人力资源服务机构" },
        {title:"使命", img:require("@/assets/images/qy.png"), info:"让天下劳动者<br/>快乐工作快乐生活"},
        {title:"价值观", img:require("@/assets/images/jzg.png"), info:"对行业创新<br/>产生价值"}
      ],
      listList: [
        { title: "电话号码：", info: "13117416668" },
        { title: "官网网址：", info: "https://www.china6588.com/" },
        { title: "公司邮箱：", info: "caikutong@dghang.com" },
        {title:"公司地址：", info:"上海市嘉定区安亭镇墨玉路28号嘉正国际大厦"},
      ]
    }
  }
}
</script>
<style lang="less" scoped>

</style>
